import { render, staticRenderFns } from "./update-profile-modal.vue?vue&type=template&id=52942776&scoped=true&"
import script from "./update-profile-modal.ts?vue&type=script&lang=ts&"
export * from "./update-profile-modal.ts?vue&type=script&lang=ts&"
import style0 from "./update-profile-modal.vue?vue&type=style&index=0&id=52942776&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52942776",
  null
  
)

export default component.exports